(function()
{
	'use strict';

	angular.module('app', [ 'atsc', 'crossyn', 'ngMaterial', 'ngResource', 'zxcvbn' ])
	.directive('convertToNumber', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel)
			{
				ngModel.$parsers.push(function(val)
				{
					return parseInt(val, 10);
				});
				ngModel.$formatters.push(function(val)
				{
					return '' + val;
				});
			}
		};
	})
	.directive('convertToDate', function()
	{
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel)
			{
				ngModel.$formatters.push(function(val)
				{
					return new Date(val);
				});
			}
		};
	})
	.directive('progressbarList', [ '$compile', function($compile)
	{
		return {
			restrict: 'E',
			link: function(scope, elem, attrs)
			{
				var ol													= $(document.createElement('ol'));

				ol.addClass('progressbar');

				elem.find('> item').each(function()
				{
					var li												= $(document.createElement('li'));

					li.html($(this).html());

					for (var i = 0; i < this.attributes.length; i = i + 1)
					{
						li.attr(this.attributes[i].name, this.attributes[i].value);
					}

					li.attr('title', $(this).text());

					var arrowStart										= $(document.createElement('span'));
					var arrowStartOverlay								= $(document.createElement('span'));
					var arrowEnd										= $(document.createElement('span'));
					var arrowEndOverlay									= $(document.createElement('span'));

					arrowStart.addClass('arrow start');
					arrowStartOverlay.addClass('arrow start overlay');
					arrowEnd.addClass('arrow end');
					arrowEndOverlay.addClass('arrow end overlay');

					li.append(arrowStart);
					li.append(arrowStartOverlay);
					li.append(arrowEnd);
					li.append(arrowEndOverlay);

					ol.append(li);
				});

				elem.replaceWith($compile(ol)(scope));
			}
		}
	}])
	.run(['$rootScope', function($rootScope)
	{
		$rootScope.range = function(min, max, step)
		{
			min															= parseFloat('' + min);
			max															= parseFloat('' + max);
			step														= step ? parseFloat('' + step) : 1;

			var input = [];

			for (var i = min; i <= max; i += step)						{ input.push(i); }

			return input;
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('RegisterController', ['$scope', '$element', '$http', '$q', '$mdDialog', '$util', '$compile', '$controller', '$attrs', '$window', function($scope, $element, $http, $q, $mdDialog, $util, $compile, $controller, $attrs, $window)
	{
		var controller											= $controller('FormController', {$scope: $scope, $attrs: $attrs, $element: $element});

		angular.extend(this, controller);

		var formController										= controller.name;
		var showRecaptcha;

		$scope.showSwipe = function(datasharingList, datasharingType)
		{
			if (datasharingList != null)
			{
				if (datasharingList.BUSINESS_TRIPS == false && (datasharingType == 'BUSINESS_DRIVINGBEHAVIOUR' || datasharingType == 'BUSINESS_EMISSION' || datasharingType == 'BUSINESS_ROUTE'))
				{
					return false;
				}
				if (datasharingList.PRIVATE_TRIPS == false && (datasharingType == 'PRIVATE_DRIVINGBEHAVIOUR' || datasharingType == 'PRIVATE_EMISSION' || datasharingType == 'PRIVATE_ROUTE'))
				{
					return false;
				}
				if (datasharingList.VEHICLE == false && (datasharingType == 'VEHICLE_GENERAL' || datasharingType == 'VEHICLE_MEASUREMENTS'))
				{
					return false;
				}
			}

			return true;
		}

		$scope.checkSwipes = function(datasharingList, datasharingType, dataSharingOptions)
		{
			if (datasharingList != null)
			{
				if (datasharingType == 'BUSINESS_TRIPS')
				{
					if (datasharingList.BUSINESS_TRIPS == false)
					{
						datasharingList.BUSINESS_DRIVINGBEHAVIOUR = false;
						datasharingList.BUSINESS_EMISSION = false;
						datasharingList.BUSINESS_ROUTE = false;
					}
					else{
						var datasharingOptionsObject = JSON.parse(dataSharingOptions);

						angular.forEach(datasharingOptionsObject, function (value){
							if (value.datasharing == 'BUSINESS_DRIVINGBEHAVIOUR' && value.option != 'DISABLED')
							{
								datasharingList.BUSINESS_DRIVINGBEHAVIOUR = true;
							}
							else if (value.datasharing == 'BUSINESS_EMISSION' && value.option != 'DISABLED')
							{
								datasharingList.BUSINESS_EMISSION = true;
							}
							else if (value.datasharing == 'BUSINESS_ROUTE' && value.option != 'DISABLED')
							{
								datasharingList.BUSINESS_ROUTE = true;
							}
						});
					}
				}
				else if (datasharingType == 'PRIVATE_TRIPS')
				{
					if (datasharingList.PRIVATE_TRIPS == false)
					{
						datasharingList.PRIVATE_DRIVINGBEHAVIOUR = false;
						datasharingList.PRIVATE_EMISSION = false;
						datasharingList.PRIVATE_ROUTE = false;
					}
					else{
						var datasharingOptionsObject = JSON.parse(dataSharingOptions);
						angular.forEach(datasharingOptionsObject, function (value){
							if (value.datasharing == 'PRIVATE_DRIVINGBEHAVIOUR' && value.option != 'DISABLED')
							{
								datasharingList.PRIVATE_DRIVINGBEHAVIOUR = true;
							}
							else if (value.datasharing == 'PRIVATE_EMISSION' && value.option != 'DISABLED')
							{
								datasharingList.PRIVATE_EMISSION = true;
							}
							else if (value.datasharing == 'PRIVATE_ROUTE' && value.option != 'DISABLED')
							{
								datasharingList.PRIVATE_ROUTE = true;
							}
						});
					}
				}
				else if (datasharingType == 'VEHICLE')
				{
					if (datasharingList.VEHICLE == false)
					{
						datasharingList.VEHICLE_GENERAL = false;
						datasharingList.VEHICLE_MEASUREMENTS = false;
					}
					else{
						var datasharingOptionsObject = JSON.parse(dataSharingOptions);
						angular.forEach(datasharingOptionsObject, function (value){
							if (value.datasharing == 'VEHICLE_GENERAL' && value.option != 'DISABLED')
							{
								datasharingList.VEHICLE_GENERAL = true;
							}
							else if (value.datasharing == 'VEHICLE_MEASUREMENTS' && value.option != 'DISABLED')
							{
								datasharingList.VEHICLE_MEASUREMENTS = true;
							}
						});
					}
				}
			}
		};

		// ReCAPTCHA
		$scope.recaptchaCallback = function(token)
		{
			if (token)
			{
				$http({
					url: '/register/recaptcha',
					method: 'post',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					data: 'g-recaptcha-response=' + token
				}).then(function(response)
					{
						if (response.data.result)
						{
							$scope.solvedRecaptcha				= true;

							if ($scope.state == 'login')		{ login(); }
							else								{ $scope.next(); }
						}
						else
						{
							$mdDialog.show(
									$mdDialog.alert()
									.clickOutsideToClose(true)
									.title('Validatie mislukt')
									.htmlContent("<p>" + translate('recaptcha-datavalid/text') + "</p>")
									.ok('OK')
							);
						}
				});
			}
		};

		$window.recaptchaCallback								= $scope.recaptchaCallback;

		var getStates = function()
		{
			var states											= [];

			$element.find('div.form').each(function()
			{
				if ($(this).attr('data-name') != 'login')		{ states.push($(this).attr('data-name')); }
			});

			return states;
		};

		var verificationCodeResendAfterSubmit					= null;

		controller.afterSubmit = function(response, hasError)
		{
			if (!hasError)
			{
				if (response.data.newURL)
				{
					if (response.data.newURL == 'redirect')		{ window.top.location.href = response.data.redirectUrl; }
					else										{ $scope.state = response.data.newURL; }
				}
				else
				{
					var states									= getStates();
					var index									= states.indexOf($scope.state);

					if (index > -1)
					{
						if (states.length > (index + 1))		{ $scope.state = states[index + 1]; }
						else									{ $scope.state = 'finished'; }
					}
				}

				if (verificationCodeResendAfterSubmit)
				{
					verificationCodeResendAfterSubmit();

					verificationCodeResendAfterSubmit			= null;
				}
			}
		};

		$scope.$on('form.specificInit', function()
		{
			$scope.ready										= true;
			showRecaptcha										= controller.formData.showRecaptcha;

			if ($scope.state == undefined)						{ $scope.state = $scope.driverEmailExist ? 'login' : 'personaldata'; }

			if ($scope.redirectUrl)								{ window.location.href = $scope.redirectUrl; }
		});

		// Login / Logout Functionality
		$scope.login = function()
		{
			$scope.state = 'login';
		};

		$scope.reset = function()
		{
			$scope.state = 'personaldata';
		};

		$scope.doLogin = function()
		{
			if ($scope.email && $scope.password)
			{
				if ($scope.solvedRecaptcha || !showRecaptcha)	{ login(); }
				else											{ grecaptcha.execute(); }
			}
			else
			{
				$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('keyword/login'))
							.textContent(translate('login/error'))
							.ok('OK')
				);
			}
		};

		var login = function()
		{
			$http({
				url: '/login',
				method: 'post',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				data: $.param({ 'email': $scope.email, 'password': $scope.password })
			}).then(function(response)
			{
				if (response.data.indexOf('LoginController') > -1)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('login/errortitle'))
							.textContent(translate('login/error'))
							.ok('OK')
					);
				}
				else
				{
					window.location.reload(false);
				}
			});
		}

		$scope.doLogout = function()
		{
			$http({
				url: '/logout',
				method: 'get',
			}).then(function()
			{
				window.location.reload(false);
			});
		};

		// Next Button
		$scope.next = function()
		{
			if ($scope.solvedRecaptcha || !showRecaptcha)		{ controller.submit($scope.state); }
			else												{ grecaptcha.execute(); }
		};

		// Previous Button
		$scope.prev = function()
		{
			var states											= getStates();
			var index											= states.indexOf($scope.state);

			if (index > -1)
			{
				while (states[Math.max(0, index - 1)].startsWith('verification'))
				{
					index--;
				}

				$scope.state									= states[Math.max(0, index - 1)];
			}
		};

		// Pass height to parent frame when the state changes.
		var passHeight = function()
		{
			if (window.parent != window)
			{
				var lastHeight									= -1;
				var r											= 0;

				var scan = function()
				{
					var height									= Math.max(500, document.body.clientHeight + 128);

					if (height != lastHeight)					{ window.parent.postMessage({ 'event': 'page', 'height': height }, '*'); }

					if (++r < 100)								{ setTimeout(scan, 10); }
				}

				scan();
			}
		};

		$scope.$watch('driverVehicle', function(value)
		{
			if (value)
			{
				$scope.minDate									= new Date(moment(value['startdate'], 'DD-MM-YYYY'));
				$scope.maxDate									= new Date();

				passHeight();
			}
		});

		$scope.$watch('state', function()
		{
			passHeight();

			if ($scope.state == 'appointment')
			{
				setTimeout($element.find('.here-map').data('refresh'), 0);
			}
		});

		$scope.verificationCodeResend = function()
		{
			if		($scope.state == 'verification_email')
			{
				verificationCodeResendAfterSubmit = function()
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('keyword/resend'))
							.htmlContent(translate('emailverification/code/resend', controller.formData.email))
							.ok('OK')
					);
				};
			}

			controller.submit('personaldata');
		};

		// Info dialog email
		$scope.showInfoEmail = function(ev)
		{
			return $scope.showInfo(ev, '/register/register-info-email');
		};

		// Info dialog mobile number
		$scope.showInfoMobnr = function(ev)
		{
			return $scope.showInfo(ev, '/register/register-info-mobnr');
		};

		// Info birthdate
		$scope.showInfoBirthdate = function(ev)
		{
			return $scope.showInfo(ev, '/register/register-info-birthdate');
		};

		// Password info dialog
		$scope.showInfoPassword = function(ev)
		{
			return $scope.showInfo(ev, '/register/register-info-password');
		};

		// Fixed swipes info dialog
		$scope.showInfoFixedSwipes = function(ev, serviceName)
		{
			return $scope.showInfo(ev, '/register/register-info-fixedswipes?serviceName=' + encodeURIComponent(serviceName));
		};

		// Optional swipes info dialog
		$scope.showInfoSwipes = function(ev, swipe, serviceName)
		{
			return $scope.showInfo(ev, '/register/register-info-fixedswipes?swipe=' + encodeURIComponent(swipe.toLowerCase()) + "&serviceName=" + encodeURIComponent(serviceName));
		};

		/*var accordion = $element.find('.accordion');

		for (var i = 0; i < accordion.length; i++)
		{
			accordion[i].addEventListener("click", function()
			{
				$('.accordion').removeClass('active');
				for (var i = 0; i < $('.panel').length; i++)	{ $('.panel')[i].style.display = "none"; }

				this.classList.toggle("active");
				var panel										= this.nextElementSibling;

				if (panel.style.display === "block")			{ panel.style.display = "none"; }
				else											{ panel.style.display = "block"; }
			});
		}*/

		controller.formData.appointment							= {};

		$scope.$watch('marker', function(newValue)
		{
			if (newValue && newValue.attributes != undefined)
			{
				$scope.companyname								= newValue.attributes.companyname;
				$scope.street									= newValue.attributes.address.street;
				$scope.housenumber								= newValue.attributes.address.houseNumber;
				$scope.city										= newValue.attributes.address.city;
				$scope.country									= newValue.attributes.address.country;
				$scope.phonenumber								= newValue.attributes.address.phoneNumber;

				controller.formData.appointment.workshop		= newValue.attributes;
			}
		});

		$scope.$watch('marker', passHeight);

		var formatDateTime = function(date, time)
		{
			return moment(moment(date).format('YYYY-MM-DD') + 'T' + time + ':00', 'YYYY-MM-DD\'T\'hh:mm:ss').format();
		}

		$scope.addWishDate = function()
		{
			if ($scope.wishDates.length < 3)					{ $scope.wishDates.push(''); }
		}

		$scope.removeWishDate = function(index)
		{
			$scope.wishDates.splice(index, 1);
		}

		$scope.$watch('wishDates', function(newVal, oldVal)
		{
			if (newVal != undefined)
			{
				var dates																	= [];

				for (var val in newVal)
				{
					if (newVal[val].date != undefined && newVal[val].time != undefined)
					{
						if ((newVal[val].date !== oldVal[val].date) || (newVal[val].time !== oldVal[val].time))
						{
							dates[val] = formatDateTime(newVal[val].date, newVal[val].time);
						}
					}
				}

				if (dates.length > 0)							{ controller.formData.appointment.wishdates = dates; }
			}
		}, true);

		$scope.$watchCollection('wishDates', passHeight);

		$element.css('display', 'block');
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('ServiceController', ['$scope', '$window', "$q", "$http", '$parse', '$mdDialog', '$element', '$compile', '$templateCache','$timeout', function($scope, $window, $q, $http, $parse, $mdDialog, $element, $compile, $templateCache, $timeout)
	{
		var controller																= this;
		var id																		= $element.attr('data-id');

		var refresh = function()
		{
			var $listElement														= $element.parents('.service-list').first();

			$http({
				url: '/services',
				method: 'get'
			}).then(function(response)
			{
				$listElement.empty();

				var $newElement														= $compile($(response.data).find('.service-list').html())($scope.$parent);

				$listElement.append($newElement);
			});
		};

		$scope.toggleOpen = function(value)
		{
			if (!$scope.$parent.openedIds)											{ $scope.$parent.openedIds = []; }

			if ($scope.$parent.openedIds.indexOf(id) == -1)							{ $scope.$parent.openedIds.push(value); }
			else
			{
				var index = $scope.$parent.openedIds.indexOf(value);

				if (index !== -1)													{ $scope.$parent.openedIds.splice(index, 1); }
			}
		};

		$scope.isOpen = function()
		{
			return $scope.$parent.openedIds && $scope.$parent.openedIds.indexOf(id) > -1;
		};

		$scope.showSwipe = function(datasharingList, datasharingType)
		{
			if (datasharingList != null)
			{
				if (datasharingList.BUSINESS_TRIPS == false && (datasharingType == 'BUSINESS_DRIVINGBEHAVIOUR' || datasharingType == 'BUSINESS_EMISSION' || datasharingType == 'BUSINESS_ROUTE'))
				{
					return false;
				}
				if (datasharingList.PRIVATE_TRIPS == false && (datasharingType == 'PRIVATE_DRIVINGBEHAVIOUR' || datasharingType == 'PRIVATE_EMISSION' || datasharingType == 'PRIVATE_ROUTE'))
				{
					return false;
				}
				if (datasharingList.VEHICLE == false && (datasharingType == 'VEHICLE_GENERAL' || datasharingType == 'VEHICLE_MEASUREMENTS'))
				{
					return false;
				}
			}

			return true;
		}

		$scope.checkSwipes = function(datasharingList, datasharingType, dataSharingOptions)
		{
			if (datasharingList != null)
			{
				if (datasharingType == 'BUSINESS_TRIPS')
				{
					if (datasharingList.BUSINESS_TRIPS == false)
					{
						datasharingList.BUSINESS_DRIVINGBEHAVIOUR = false;
						datasharingList.BUSINESS_EMISSION = false;
						datasharingList.BUSINESS_ROUTE = false;
					}
					else{
						var datasharingOptionsObject = JSON.parse(dataSharingOptions);

						angular.forEach(datasharingOptionsObject, function (value){
							if (value.datasharing == 'BUSINESS_DRIVINGBEHAVIOUR' && value.option != 'DISABLED')
							{
								datasharingList.BUSINESS_DRIVINGBEHAVIOUR = true;
							}
							else if (value.datasharing == 'BUSINESS_EMISSION' && value.option != 'DISABLED')
							{
								datasharingList.BUSINESS_EMISSION = true;
							}
							else if (value.datasharing == 'BUSINESS_ROUTE' && value.option != 'DISABLED')
							{
								datasharingList.BUSINESS_ROUTE = true;
							}
						});
					}
				}
				else if (datasharingType == 'PRIVATE_TRIPS')
				{
					if (datasharingList.PRIVATE_TRIPS == false)
					{
						datasharingList.PRIVATE_DRIVINGBEHAVIOUR = false;
						datasharingList.PRIVATE_EMISSION = false;
						datasharingList.PRIVATE_ROUTE = false;
					}
					else{
						var datasharingOptionsObject = JSON.parse(dataSharingOptions);
						angular.forEach(datasharingOptionsObject, function (value){
							if (value.datasharing == 'PRIVATE_DRIVINGBEHAVIOUR' && value.option != 'DISABLED')
							{
								datasharingList.PRIVATE_DRIVINGBEHAVIOUR = true;
							}
							else if (value.datasharing == 'PRIVATE_EMISSION' && value.option != 'DISABLED')
							{
								datasharingList.PRIVATE_EMISSION = true;
							}
							else if (value.datasharing == 'PRIVATE_ROUTE' && value.option != 'DISABLED')
							{
								datasharingList.PRIVATE_ROUTE = true;
							}
						});
					}
				}
				else if (datasharingType == 'VEHICLE')
				{
					if (datasharingList.VEHICLE == false)
					{
						datasharingList.VEHICLE_GENERAL = false;
						datasharingList.VEHICLE_MEASUREMENTS = false;
					}
					else{
						var datasharingOptionsObject = JSON.parse(dataSharingOptions);
						angular.forEach(datasharingOptionsObject, function (value){
							if (value.datasharing == 'VEHICLE_GENERAL' && value.option != 'DISABLED')
							{
								datasharingList.VEHICLE_GENERAL = true;
							}
							else if (value.datasharing == 'VEHICLE_MEASUREMENTS' && value.option != 'DISABLED')
							{
								datasharingList.VEHICLE_MEASUREMENTS = true;
							}
						});
					}
				}
			}
		};

		$scope.removeAccess = function()
		{
			var LookupController = function($scope, $mdDialog, $http, $compile, $q, $util, $timeout)
			{
				var controller														= this;

				var remove = function(all)
				{
					$http({
						url: '/services/' + id + '/remove-access' + (all ? '/all' : ''),
						method: 'post'
					}).then(function(response)
					{
						if (response.data.error && response.data.error.message)
						{
							var msg													= response.data.error.message;

							$mdDialog.show(
								$mdDialog.alert()
									.clickOutsideToClose(true)
									.textContent(msg)
									.ok('OK')
							).then(function()
							{
								refresh();
							});
						}
						else														{ reload(); }
					});

					$mdDialog.hide();
				};

				controller.removeAll = function()
				{
					remove(true);
				};

				controller.remove = function()
				{
					remove(false);
				};

				controller.cancel = function()
				{
					$mdDialog.cancel();
				};
			}

			LookupController.$inject											= [ '$scope', '$mdDialog', '$http', '$compile', '$q', '$util', '$timeout', 'LookupService' ];

			var uri																= '/services/' + id + '/remove-access-dialog';

			$templateCache.remove(uri);

			$mdDialog.show({
				'templateUrl': uri,
				'controller': LookupController,
				'controllerAs': 'controller',
				'multiple': true
			});
		};

		$scope.format = function(d)
		{
			var format															= moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT');

			return d ? moment(d).format(format) : '';
		};

		var DialogController = function($scope, $mdDialog)
		{
			$scope.ok = function()
			{
				$mdDialog.hide();
			};
		};

		DialogController.$inject												= ["$scope", "$mdDialog" ];

		$scope.showInfo = function(ev, url)
		{
			$mdDialog.show(
			{
				controller: DialogController,
				templateUrl: url,
				parent: angular.element(document.body),
				targetEvent: ev,
				clickOutsideToClose: true
			});
		};

		// Fixed swipes info dialog
		$scope.showInfoFixedSwipes = function(ev, serviceName)
		{
			return $scope.showInfo(ev, '/register/register-info-fixedswipes?serviceName=' + encodeURIComponent(serviceName));
		};

		// Optional swipes info dialog
		$scope.showInfoOptionalSwipes = function(ev, serviceName)
		{
			return $scope.showInfo(ev, '/register/register-info-optionalswipes?serviceName=' + encodeURIComponent(serviceName));
		};

		controller.submit = function()
		{
			$http({
				url: '/services/' + id + '/change-consent?_=' + new Date().getTime(),
				method: 'post',
				data: $scope.datasharingTypes
			}).then(function(response)
			{
				if (response.data.error && response.data.error.message)
				{
					var msg														= response.data.error.message;

					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.textContent(msg)
							.ok('OK')
					).then(function()
					{
						refresh();
					});
				}
				else
				{
					$timeout(function(){ refresh() }, 0);
				}
			});
		};
	}]);
})();

(function()
{
	'use strict';

	angular.module('app').controller('MainController', ['$scope', '$mdDialog', '$http', 'LookupService', '$element', function($scope, $mdDialog, $http, LookupService, $element)
	{
		var mainController														= this;

		var getVehicleName = function(vehicle)
		{
			if		(!vehicle)													{ return ''; }
			else if (!vehicle.brand || !vehicle.model || !vehicle.type)			{ return vehicle.vin; }
			else																{ return vehicle.brand + ' ' + vehicle.model + ' ' + vehicle.type + ' (' + vehicle.vin + ')'; }
		};

		var setVehicle = function(driverVehicle, save)
		{
			mainController.driverVehicle										= driverVehicle._id;
			mainController.vehicle 												= getVehicleName(driverVehicle);

			if (save !== false)
			{
				try																{ window.sessionStorage.driverVehicle = JSON.stringify(driverVehicle); }
				catch (ex)														{ /* no problem */ }
			}
		}

		try
		{
			if (window.sessionStorage.driverVehicle !== undefined)
			{
				var driverVehicle												= JSON.parse(window.sessionStorage.driverVehicle);

				if (driverVehicle)												{ setVehicle(driverVehicle, false); }
			}
			else
			{
				mainController.driverVehicle									= $element.attr('data-driver-vehicle');
				mainController.vehicle											= $element.attr('data-vehicle');
			}
		}
		catch (ex)																{ /* no problem */ }

		setGlobalAjaxHeader('X-Driver-Vehicle-ID', function()
		{
			return mainController.driverVehicle ? mainController.driverVehicle : '';
		});

		var vehicleSet = false;

		$scope.$watch('mainController.vehicle', function(newValue)
		{
			if (vehicleSet)
			{
				if (newValue)													{ reload(); }
			}
			else																{ vehicleSet = true; }
		});

		mainController.getVehicle = function()
		{
			var LookupController = function($scope, $mdDialog, $http, $compile, $q, $util, $timeout, LookupService, $parse)
			{
				var controller													= this;

				controller.formData												= {};

				controller.select = function()
				{
					$scope.childTableControllers[0].getSelectedRows().then(function(rows)
					{
						if (rows.length > 0)									{ $mdDialog.hide(rows); }
					});
				}

				controller.cancel = function()
				{
					$mdDialog.cancel();
				};

				$scope.$on('table.onToggleSelection', function(e, b, row, tableController)
				{
					if (b && tableController.getOption('singleSelectionMode'))
					{
						tableController.getSelectedRows().then(function(rows)
						{
							$mdDialog.hide(rows);
						});
					}
				});
			};

			LookupController.$inject											= [ '$scope', '$mdDialog', '$http', '$compile', '$q', '$util', '$timeout', 'LookupService', '$parse' ];

			$mdDialog.show({
				'templateUrl': '/vehicle-lookup',
				'controller': LookupController,
				'controllerAs': 'controller',
				'skipHide': true, // Angular 1.5.8
				'multiple': true // Angular 1.5.9+
			}).then(function(rows) { setVehicle(rows[0], true); });
		};

		mainController.getMessage = function(id)
		{
			window.location.href = '/#/messages/' + id;
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('AccountController', ['$scope', '$element', '$http', '$q', '$mdDialog', 'addressLookup', '$util', function($scope, $element, $http, $q, $mdDialog, addressLookup, $util)
	{
		var controller										= this;

		controller.parseToDate = function(a)
		{
			return moment.utc(a).toDate();
		};

		controller.getPasswordStrength = function()
		{
			if (controller.passwordStrength)
			{
				var s										= controller.passwordStrength.score;

				if (s == 0 || s == 1)						{ return translate('passwordstrength/weak'); }
				else if (s == 2)							{ return translate('passwordstrength/average'); }
				else if (s == 3)							{ return translate('passwordstrength/reasonable'); }
				else if (s == 4)							{ return translate('passwordstrength/strong'); }
			}
			else											{ return ''; }
		};

		var validationDialog = function(description, type, id)
		{
			return $q(function(resolve, reject)
			{
				var validate = function()
				{
					$mdDialog.show(
					{
						controller: ['$mdDialog', function($mdDialog)
						{
							var dialogController				= this;

							dialogController.ok = function()
							{
								$http({
									url: '/validator/' + type,
									method: 'get',
									cache: false,
									params: { 'id': id, 'code': dialogController.code }
								}).then(function(response)
								{
									if (response && response.data && response.data.valid)
									{
										$mdDialog.hide();

										resolve(dialogController.code);
									}
									else
									{
										$mdDialog.show(
											$mdDialog.alert()
												.clickOutsideToClose(true)
												.title(translate('account/error-title'))
												.textContent(translate('account/error-text'))
												.ok('OK')
										).then(function()
										{
											validate();
										});
									}
								}, function()
								{
									$mdDialog.show(
										$mdDialog.alert()
											.clickOutsideToClose(true)
											.title(translate('account/error-title'))
											.textContent(translate('account/network-error'))
											.ok('OK')
									).then(function()
									{
										validate();
									});
								});
							};

							dialogController.cancel = function()
							{
								$mdDialog.hide();
							};
						}],
						controllerAs: 'controller',
						template: '<md-dialog ng-cloak><md-dialog-content><div class="md-dialog-content">' +
									'<p>' + description + '</p>' +
									'<div layout="row" layout-margin><div flex="50"><md-input-container class="md-block"><label class="md-no-float">Code</label>' +
									'<input type="text" ng-model="controller.code" />' +
									'</md-input-container></div></div>' +
									'</md-dialog-content>' +
									'<md-dialog-actions layout="row"><button ng-click="controller.ok()" class="btn" style="margin-right: 20px;"><b>OK</b></button><button ng-click="controller.cancel()" class="btn" style="margin-right: 20px;"><b>'+ translate('keyword/cancel') +'</b></button></md-dialog-actions>' +
									'</md-dialog>',
						parent: angular.element(document.body),
						clickOutsideToClose: false
					});
				};

				validate();
			})
		};

		validateEmail = function()
		{
			return $q(function(resolve, reject)
			{
				var $formController								= $scope.$$childHead.formController1;

				$http({
					url: '/validator/email',
					method: 'post',
					params: { 'data': $formController.formData.email }
				}).then(function(response)
				{
					if (response && response.data)
					{
						if (response.data.error)				{ reject(response.data.error); }
						else
						{
							$formController.formData.emailId	= response.data.id;

							validationDialog(translate('account/emailverification/text', $formController.formData.email), 'email', response.data.id).then(function(code)
							{
								$formController.formData.emailCode = code;
								resolve();
							}, reject);
						}
					}
				});
			});
		};

		validatePhoneNumber = function()
		{
			return $q(function(resolve, reject)
			{
				var $formController							= $scope.$$childHead.formController1;

				$http({
					url: '/validator/phone',
					method: 'post', // formData['controller.forms.personalData'].phoneNumber + '|' + formData['controller.forms.personalData'].country
					params: { 'data': $formController.formData.phoneNumber + '|' + $formController.formData.country }
				}).then(function(response)
				{
					if (response && response.data)
					{
						if (response.data.error)			{ reject(response.data.error); }
						else
						{
							$formController.formData.phoneNumberId = response.data.id;

							validationDialog(translate('account/phoneverification/text', $formController.formData.phoneNumber), 'phone', response.data.id).then(function(code)
							{
								$formController.formData.phoneNumberCode = code;
								resolve();
							}, reject);
						}
					}
				});
			});
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').controller('DriverController', ['$scope', '$element', '$http', '$q', '$mdDialog', function($scope, $element, $http, $q, $mdDialog)
	{
		var controller										= this;


		controller.accountDeletion = function()
		{
			$mdDialog.show(
				$mdDialog.confirm()
					.title(translate('keyword/confirmation'))
					.textContent(translate("driver/account/deletion"))
					.ok(translate('keyword/yes'))
					.cancel(translate('keyword/no'))
			).then(function()
			{
				$http({
									url: '/account/delete',
									method: 'post',
								}).then(function(response)
								{
									$mdDialog.show(
					$mdDialog.alert()
						.clickOutsideToClose(true)
						.title(translate('keyword/confirmation'))
						.textContent(translate('driver/account/delete/sent'))
						.ok('OK'))
									});
										});
		};

		controller.accountClear = function()
		{
			$mdDialog.show(
				$mdDialog.confirm()
					.title(translate('keyword/confirmation'))
					.textContent(translate("driver/account/clear"))
					.ok(translate('keyword/yes'))
					.cancel(translate('keyword/no'))
			).then(function()
			{
				$http({
									url: '/account/clear',
									method: 'post',
								}).then(function(response)
								{
									$mdDialog.show(
					$mdDialog.alert()
						.clickOutsideToClose(true)
						.title(translate('keyword/confirmation'))
						.textContent(translate('driver/account/clear/sent'))
						.ok('OK'))
									});
										});
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('app').service('addressLookup', ['$q', '$resource', function($q, $resource)
	{
		var service =
		{
			lookup: function(country, zipcode, streetNumber)
			{
				return $q(function(resolve, reject)
				{
					$resource('/addresslookup/:country/:zipcode/:streetNumber', { country: '@country', zipcode: '@zipcode', streetNumber: '@streetNumber' },
					{
						get: { method: 'GET'}
					}).get({ country: country, zipcode: zipcode, streetNumber: streetNumber}, function(address)
					{
						resolve(address);
					}, function()
					{
						reject();
					});
				});
			}
		};

		return service;
	}]);
})();

